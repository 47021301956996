.App {
    margin: 100px;
    text-align: center;
}

.Accounts {
    margin: 0 auto;
    margin-top: 10rem;
}

.btn-grad {
    background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
}

.btn-grad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.btn-grad:disabled {
    background-position: right center;
    /* change the direction of the change here */
    color: "red";
    text-decoration: none;
}